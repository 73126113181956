import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { ApiReloadStatus, GLogger } from './page-header.model';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {
  apiReloadStatus$: BehaviorSubject<ApiReloadStatus> = new BehaviorSubject({});

  gLog({ type = '', action = '', category = '', label = '', value = 0 }: GLogger) {
    gtag(type, action, { event_category: category, event_label: label, value });
  }

  errorHelper = (message: string) => {
    this.gLog({
      type: 'event',
      action: 'Error',
      category: 'Angular Service',
      label: message,
    });
    return throwError(() => new Error(message));
  }

  public setApiReloadStatus = (param: ApiReloadStatus) => {
    const updatedStatus = {
      ...this.apiReloadStatus$.value,
      ...param,
    };
    this.apiReloadStatus$.next(updatedStatus);
  }

  resetCache() {
    this.apiReloadStatus$.next({});
  }
}
