import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { AdminCompanyItem, AdminCompanyResponseTime, LogoInformation } from './company.model';

@Injectable({
  providedIn: 'root',
})

export class CompanyService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private companyDataset: AdminCompanyResponseTime = {} as AdminCompanyResponseTime;
  companyUrl = API_SERVICE_URL + '/companyinformation';

  getCompanyInformation = (): Observable<AdminCompanyResponseTime> => {
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'company');
    const existingValue = this.companyDataset;
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    return this.http.get<AdminCompanyItem[]>(this.companyUrl)
      .pipe(
        map((result: AdminCompanyItem[]) => {
          const companyResult: AdminCompanyResponseTime = {
            requestAsOf: new Date(),
            companyList: result || [],
          };
          this.companyDataset = companyResult;
          this.updateReloadStatus({ company: false });
          return companyResult;
        })
      );
  }

  getCompanyDetail = (companyId: number): Observable<AdminCompanyItem> => {
    if (!companyId) {
      return this.pageService.errorHelper('getCompanyDetail: missing the required params.');
    }

    const existingValue = this.companyDataset;
    if (!isEmpty(existingValue)) {
      return of(existingValue.companyList.find((company: AdminCompanyItem) => company.companyId === companyId)!);
    }

    return this.http.get<AdminCompanyItem>(`${this.companyUrl}/${companyId}`);
  }

  postCompanyInformation = (postData: AdminCompanyItem) => {
    return this.http.post<AdminCompanyItem>(this.companyUrl, postData);
  }

  postUploadFile = (formData: FormData) => {
    const fileUploadUrl = this.companyUrl + '/upload';

    return this.http.post<LogoInformation>(fileUploadUrl, formData);
  }

  editCompanyInformation = (postData: AdminCompanyItem) => {
    const { companyId } = postData;

    if (!companyId) {
      return this.pageService.errorHelper('editCompanyInformation: missing the required params.');
    }

    const companyEditUrl = `${this.companyUrl}/${companyId}`;
    return this.http.post<AdminCompanyItem>(companyEditUrl, postData);
  }

  deleteCompanyInformation = (companyId: number) => {
    if (!companyId) {
      return this.pageService.errorHelper('deleteCompanyInformation: missing the required params.');
    }
    const companyDeleteUrl = `${this.companyUrl}/${companyId}`;
    return this.http.delete(companyDeleteUrl);
  }

  updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  resetCache() {
    this.companyDataset = {} as AdminCompanyResponseTime;
  }
}
